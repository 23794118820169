/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    br: "br"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Wenn du Dich auf den Weg nach Ithaka machst", React.createElement(_components.br), "\n", "Hoffe ich, dass Dein Weg lang sein wird,", React.createElement(_components.br), "\n", "Voller Abenteuer, voller Entdeckungen."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Behalte Ithaka immer in Deinem Kopf.", React.createElement(_components.br), "\n", "Dorthin zu gelangen, ist Deine Bestimmung.", React.createElement(_components.br), "\n", "Doch überstürze die Reise nicht.", React.createElement(_components.br), "\n", "Es ist besser, wenn sie jahrelang dauert,", React.createElement(_components.br), "\n", "Damit du alt bist, wenn Du die Insel erreichst,", React.createElement(_components.br), "\n", "Reich an allem, was Du auf dem Weg gewonnen hast,", React.createElement(_components.br), "\n", "Nicht erwartend, dass Ithaka Dich reich macht."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Ithaka hat Dir die wunderbare Reise geschenkt.", React.createElement(_components.br), "\n", "Durch sie allein bist Du hinaus gefahren", React.createElement(_components.br), "\n", "Jetzt hat sie dir nichts mehr zu geben."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Findest Du sie arm, wird Ithaka Dich nicht getäuscht haben.", React.createElement(_components.br), "\n", "So weise Du geworden sein wirst, so voller Erfahrung,", React.createElement(_components.br), "\n", "Wirst Du bis dahin verstanden haben, was diese Ithakas bedeuten."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
