import GATSBY_COMPILED_MDX from "/opt/build/repo/content/leaves/24082022-ithaka/index.de.mdx";
import React from "react";
import {graphql} from "gatsby";
import ImageLogo from "../components/imageLogo";
import useTranslations from "../components/useTranslations";
const LeafTemplate = ({data, location, children}) => {
  const {externalLink} = useTranslations();
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "relative max-w-md mx-auto md:max-w-2xl mt-6 min-w-0 break-words w-full mb-6 mt-16"
  }, React.createElement("div", {
    className: "px-2 py-2 bg-mint-green shadow-lg rounded-xl"
  }, React.createElement("div", {
    className: "flex flex-wrap justify-center"
  }, React.createElement("div", {
    className: "w-full flex justify-center"
  }, React.createElement("div", {
    className: "relative"
  }, React.createElement("div", {
    className: "rounded-full bg-white border-2  border-mint-green align-middle absolute -m-16 -ml-20 lg:-ml-16 max-w-[150px]"
  }, React.createElement(ImageLogo)))), React.createElement("div", {
    className: "mt-24"
  })), React.createElement("div", {
    className: "text-center mt-2 py-6 px-4 border-b "
  }, React.createElement("h3", {
    className: "text-xl font-bold"
  }, data.mdx.frontmatter.title), React.createElement("div", {
    className: "mt-4 text-xl leading-normal mb-1 font-light"
  }, children), React.createElement("div", {
    className: "text-md mt-5 mb-2"
  }, React.createElement("i", {
    className: "fas fa-map-marker-alt mr-2 text-justify opacity-75"
  }), data.mdx.frontmatter.quoteAuthor)), React.createElement("div", {
    className: "my-4 py-6 text-center"
  }, React.createElement("div", {
    className: "flex flex-wrap justify-center "
  }, React.createElement("div", {
    className: "w-full px-4"
  }, React.createElement("p", {
    className: "text-2xl font-bold leading-relaxed text-slate-600"
  }, data.mdx.frontmatter.verse)))), React.createElement("div", {
    className: "py-12 mt-2 mb-2 border-t text-center"
  }, React.createElement("div", {
    className: "flex flex-wrap justify-center"
  }, React.createElement("button", {
    className: "bg-mint-green2 font-normal py-2 px-4 border hover:text-white rounded-full"
  }, React.createElement("a", {
    href: data.mdx.frontmatter.musicLink
  }, React.createElement("p", {
    className: "font-bold"
  }, "♫ \"", data.mdx.frontmatter.musicTitle, "\" ♫"), externalLink)))))));
};
export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date
        slug
        quoteAuthor
        verse
        musicTitle
        musicLink
      }
    }
  }
`;
LeafTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(LeafTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
